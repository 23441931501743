const ADMIN = "admin";
const DOCTOR = "doctor";
const PATIENT = "patient";
const SPECIALITY = "speciality";
const DEGREE = "degree";

// User
export const USER_LOGIN = `${ADMIN}/user/login`;

// /admin/common/delete-user/:userId
export const DELETE_USER = `${ADMIN}/common/delete-user`;

// Doctors

// /admin/doctor/add-new-doctor

export const ADD_DOCTOR = `${ADMIN}/${DOCTOR}/add-doctor`;

export const GET_ALL_DOCTORS_LIST = `${ADMIN}/${DOCTOR}/get-all-doctors`;
// /admin/doctor/details/:doctorId
export const GET_DOCTOR_DETAILS = `${ADMIN}/${DOCTOR}/details`;

// /admin/doctor/all-appointments/:doctorId
export const GET_DOCTOR_APPOINTMENT_LIST = `${ADMIN}/doctor/all-appointments`;

// Patient

// /admin/patient/get-all
export const GET_ALL_PATIENT_LIST = `${ADMIN}/${PATIENT}/get-all`;

// /admin/patient/all-appointments/:patientId
export const GET_PATIENT_APPOINTMENT_LIST = `${ADMIN}/patient/all-appointments`;

// /admin/patient/all-prescriptions/:patientId
export const GET_PATIENT_PRESCRIPTION_LIST = `${ADMIN}/patient/all-prescriptions`;

//Speciality

///admin/speciality/all
export const GET_ALL_SPECIALITY_LIST = `${ADMIN}/${SPECIALITY}/all`;

// /admin/speciality/create
export const ADD_SPECIALITY_NAME = `${ADMIN}/${SPECIALITY}/create`;

// /admin/speciality/:specialityId
export const UPDATE_SPECIALITY_NAME = `${ADMIN}/${SPECIALITY}`;

// /admin/speciality/:specialityId
export const DELETE_SPECIALITY_NAME = `${ADMIN}/${SPECIALITY}`;

//Degree

// /admin/degree/all
export const GET_ALL_DEGREE_LIST = `${ADMIN}/${DEGREE}/all`;

// /admin/degree/create
export const ADD_DEGREE_NAME = `${ADMIN}/${DEGREE}/create`;

// /admin/degree/:degreeId
export const UPDATE_DEGREE_NAME = `${ADMIN}/${DEGREE}`;

// /admin/degree/:degreeId
export const DELETE_DEGREE_NAME = `${ADMIN}/${DEGREE}`;

// Dashboard

// admin/dashboard/statistics

export const GET_DASHBOARD_DATA = `${ADMIN}/dashboard/statistics`;

// /admin/dashboard/resent-users?type=doctor
export const GET_RECENT_ADDED_DOCTORS = `${ADMIN}/dashboard/resent-users?type=doctor`;

// /admin/settings/payment-partners

export const GET_PAYMENT_PARTNERS = `${ADMIN}/settings/payment-partners`;

// /admin/settings/currency

export const GET_ALL_CURRENCY = `${ADMIN}/settings/get-currency`;

///admin/settings/get-platform-fees

export const GET_PLATFORM_CHARGE = `${ADMIN}/settings/get-platform-fees`;

// /admin/settings/update-payment-partners

export const UPDATE_PAYMENT_PARTNERS = `${ADMIN}/settings/update-payment-partners`;

// /admin/settings/update-currency

export const UPDATE_CURRENCY_OPTIONS = `${ADMIN}/settings/update-currency`;

// /admin/settings/update-platform-fees/:id

export const UPDATE_PLATFORM_FEE = `${ADMIN}/settings/update-platform-fees`;

// /admin/doctor/rollout-payment-for-doctor/:doctorId

export const ADMIN_ROLLOUT_PAYMENT = `${ADMIN}/doctor/rollout-payment-for-doctor`;

// /admin/payouts/all

export const ADMIN_GET_PAYOUT_LIST = `${ADMIN}/payouts/all`;

// /admin/payouts/by-doctor/:doctorId

export const GET_DOCTOR_PAYOUT_LIST = `${ADMIN}/payouts/by-doctor`;

// /admin/doctor/get-available-balance/:doctorId

export const GET_DOCTOR_AVAILABLE_BALANCE = `${ADMIN}/doctor/get-available-balance`;

export const CHANGE_STATUS_BY_ADMIN = `${ADMIN}/doctor/update-status`;

export const CHANGE_DOC_STATUS_BY_ADMIN = `${ADMIN}/doctor/update-doc-status`;

export const DELETE_DOCTOR_DOCUMENTS = "/common/delete-certificate";

export const GET_NOTIFICATION = "/common/get-notifications";
export const READ_NOTIFICATION = "/common/read-notifications";

export const GET_TNC = "/admin/settings/get-tnc";
export const UPDATE_TNC = "/admin/settings/tnc";

export const ADD_NEW_ADMIN_USER = "/admin/user/create";
export const GET_ALL_ADMIN_USERS = "/admin/user/get-all-users";
export const DELETE_ADMIN_USER = "/admin/user/delete";

export const UPDATE_USER_STATUS = "/user/update-user-status";

export const GET_USER_DETAILS_BY_TOKEN = "/common/get-user-details";

export const APPROVE_REJECT_DOCTOR = "/admin/doctor/approve-or-reject";
