import { notification } from "antd";
import { t } from "i18next";
import { isBoolean, isEmpty, isNull, isNumber, isString, isUndefined } from "lodash";
import moment from "moment";
import localStore from "store2";

export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";

// user Type constants
export const TYPE_DOCTOR = "doctor";
export const TYPE_DOCTOR_VALUE = 0;
export const TYPE_PATIENT = "patient";
export const TYPE_PATIENT_VALUE = 1;

export const getValidDataFromResponse = ({ response, isRequired = false, isWarning = false }) => {
  try {
    if (isValidObject(response, ["success", "message", "data"])) {
      const { message, data, success, isLogout = false } = response;

      if (isLogout) {
        throw new Error("Go For Logout.");
      }

      if (success && isRequired) {
        sendNotification({ message: message, type: SUCCESS });
      } else if (!success) {
        sendNotification({ message: message, type: ERROR });
      }
      return { data, error: !success };
    }
    throw new Error("Not a valid response");
  } catch (error) {
    console.log("🚀 ~ file: utilities.js:29 ~ error:", error);
    return { data: [], error: true };
  }
};

export const sendNotification = ({ type, message, ...rest }) => {
  const direction = localStore.get("direction") === "rtl" ? "rtl" : "ltr";
  const options = {
    duration: 3,
    placement: direction === "rtl" ? "topLeft" : "topRight",
  };

  notification[type]({
    message: t(message),
    ...options,
    ...rest,
  });
};

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj, keys) => {
  if (isValidArray(keys)) {
    const newObjKeys = isValidObject(obj, []) ? Object.keys(obj) : [];

    if (!isValidArray(newObjKeys)) {
      return false;
    }

    let isValid = keys.length;
    keys.forEach((key) => {
      if (newObjKeys.includes(key)) {
        isValid -= 1;
      }
    });
    return isValid === 0;
  }
  return obj && Object.keys(obj).length > 0;
};

export const getAllRequiredHeaders = () => {
  const token = localStore.get("token") || "undefined";
  const user_id = localStore.get("user_id") || "undefined";
  const userType = localStore.get("userType") || "undefined";
  return {
    Authorization: `Bearer ${token}`,
    "user-id": user_id,
    "user-type": userType,
  };
};

// export const setLocalStorageItems = ({ set = false, setAll = false, item = null, items = {} }) => {
//     if (set) {
//         const { key, value } = item;
//         localStore.set(key, value);
//     } else if (setAll) {
//         localStore.setAll(items);
//     }
// };

// export const getLocalStorageItems = ({ get = false, getAll = false, key = null, keys = {} }) => {
//     if (get) {
//         const value = localStore.get(key);
//         return { [key]: value };
//     } else if (getAll) {
//         return localStore.getAll(keys);
//     }
//     return null;
// };

export const isValidStoredKey = (keys = []) => {
  let isValidKey = true;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (localStore.has(key)) {
      const value = localStore.get(key);
      if (isUndefined(value) && isNull(value)) {
        isValidKey = false;
        break;
      } else if (isString(value) && value.length === 0) {
        isValidKey = false;
        break;
      }
    } else {
      isValidKey = false;
      break;
    }
  }

  return isValidKey;
};

export const trimString = (str, removedStr, extraRemoveLength = 0) => {
  if (isString(str) && str.length > 0) {
    const strLength = str.search(removedStr) - extraRemoveLength;
    const latestStr = str.slice(0, strLength);
    return latestStr;
  }
  return "";
};

export const validBodyFieldsForUpdate = (obj, skipKeys) => {
  let newObj = {};

  if (isValidObject(obj)) {
    const keys = Object.keys(obj);

    for (let index = 0; index < keys.length; index++) {
      let isValid = true;
      const value = obj[keys[index]];
      if (!skipKeys.includes(keys[index])) {
        if (!isNumber(value) && !isBoolean(value) && isEmpty(value)) {
          isValid = false;
        } else if (!isNumber(value) && !isBoolean(value) && isUndefined(value)) {
          isValid = false;
        } else if (isString(value) && (value.length === 0 || value.charAt(0) === " ")) {
          isValid = false;
        } else if (typeof value === "object") {
          if (!(isValidArray(value) || isValidObject(value))) {
            isValid = false;
          }
        } else if (isNumber(value) && value === 0) {
          isValid = false;
        } else if (isNull(value)) {
          isValid = false;
        }
      }

      if (isValid) {
        newObj[keys[index]] = value;
      }
    }
  }
  return { ...newObj };
};

// export const useQueryParam = (key) => {
//     let [searchParams, setSearchParams] = useSearchParams();
//     let paramValue = searchParams.get(key);

//     let value = useMemo(() => JSURL.parse(JSURL.stringify(paramValue)), [paramValue]);

//     let setValue = useCallback(
//         (newValue) => {
//             let newSearchParams = new URLSearchParams(searchParams);
//             newSearchParams.set(key, JSURL.stringify(newValue));
//             setSearchParams(newSearchParams);
//         },
//         [key, searchParams, setSearchParams],
//     );

//     return [value, setValue];
// };

export const isValidURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const checkValidUrlPath = (pathname = [], key) => {
  const paths = pathname.split("/");
  let isValid = false;

  paths?.forEach((u) => {
    if (u === key) {
      isValid = true;
    }
  });

  return isValid;
};

export const isValidMongoId = (id) => {
  const checkMongoId = new RegExp("^[0-9a-fA-F]{24}$");
  return checkMongoId.test(id);
};

// export const getRandomMongoID = () => {
//     const id = new ObjectID().toString();
//     return id;
// };

export const isBase64 = (str) => {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const socialMediaUrlRegEx = {
  // eslint-disable-next-line no-useless-escape
  twitter: { regex: "http(?:s)?://(?:www.)?twitter.com/([a-zA-Z0-9_]+)", errorMessage: "Please enter a Twitter URL" },
  // eslint-disable-next-line no-useless-escape
  linkedIn: {
    regex: "(https?://(www.)|(www.))?linkedin.com/(mwlite/|m/)?in/[a-zA-Z0-9_.-]+/?",
    errorMessage: "Please enter a LinkedIn URL",
  },
  // eslint-disable-next-line no-useless-escape
  whatsApp: {
    regex: "^(https?://)?chat.whatsapp.com/(?:invite/)?([a-zA-Z0-9_-]{22})$",
    errorMessage: "Please enter a WhatsApp URL",
  },
  // eslint-disable-next-line no-useless-escape
  facebook: { regex: "(?:(?:http|https)://)?(?:www.)?facebook.com/?", errorMessage: "Please enter a Facebook URL" },
  // eslint-disable-next-line no-useless-escape
  email: {
    regex: "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
    errorMessage: "Please enter a Email",
  },
};

export const handleKeyPress = (e) => {
  if (e.charCode === 32) {
    e.preventDefault();
  }
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result;
      resolve({ base64String });
    };
    reader.onerror = (error) => reject(error);
  });
};

export const setLocalStorageItems = ({ set = false, setAll = false, item = {}, items = {}, skipKey = [] }) => {
  if (set) {
    localStorage.setItem(item.key, item.value);
  } else if (setAll) {
    for (const key in items) {
      if (skipKey.includes(key)) continue;
      localStorage.setItem(key, items[key]);
    }
  }
};

export const getLocalStorageItems = ({ get = false, getAll = false, key = null, keys = {} }) => {
  if (get) {
    const value = localStore.get(key);
    return { [key]: value };
  } else if (getAll) {
    return localStore.getAll(keys);
  }
  return null;
};

export const logFormData = (formData) => {
  for (const pair of formData.entries()) {
    console.log(`${pair[0]} : ${pair[1]}`);
  }
};

export const getYears = (dob) => moment().diff(dob, "years");

export const capitalizeFirstLetter = (inputString) => {
  // Check if the input is a non-empty string
  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  const arrayOfInputString = inputString.split(" ");
  const arrayOfCapitalizedString = [];

  for (let string of arrayOfInputString) {
    let updatedString = string.charAt(0).toUpperCase() + string.slice(1);
    arrayOfCapitalizedString.push(updatedString);
  }

  return arrayOfCapitalizedString.join(" ");

  // Capitalize the first letter and concatenate with the rest of the string
  // return )inputString.charAt(0).toUpperCase() + inputString.slice(1;
};

export const getAgeFromDOB = (dob = "20-12-2022") => {
  const totalMonths = moment().diff(moment(dob, "DD-MM-YYYY"), "months");
  const years = Math.floor(totalMonths / 12);
  const months = Math.round((totalMonths / 12 - years) * 12);

  if (totalMonths === 0) {
    const days = moment().diff(moment(dob, "DD-MM-YYYY"), "days");

    if (days < 10) return `${days} day`;

    return `${days} days`;
  }

  if (totalMonths < 10) return `${totalMonths} Month`;

  if (totalMonths < 12) return `${totalMonths} Months`;

  if (years < 10) {
    if (months < 10) return `${years} Year ${months} Month`;

    return `${years} Year ${months} Months`;
  }

  if (years >= 10) {
    if (months < 10) return `${years} Years ${months} Month`;

    return `${years} Years ${months} Months`;
  }
};
